import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { API_URL } from  './constants'
import { axios } from 'axios'

function AllCharacters() {

    const [serverData, setServerData] = useState([])

    useEffect(() => {
        fetch(`${API_URL}/api/allCharacters`)
        .then(async res => {
        const data = await res.json()
        console.log(data);
        setServerData(data.payload)
        })
    }, [])

    return (
      <ul>
        {serverData.length > 0 ? serverData.map((char) => 
             (
                <>
                    <li key={char._id}><Link to={`/mcu/${char.name}`}>Character: {char.name}</Link></li>
                </>
            ) 
            //ternary
        ) : <h1>...loading</h1>
      
      }
      </ul>
    );
}

export default AllCharacters;