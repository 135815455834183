import React, { useState, useEffect } from 'react';
import { API_URL } from  './constants'


import { useNavigate, useParams } from 'react-router-dom'

function OneCharacter(){
    const {name} = useParams()

    //character holds the initial mcu character document
    const [character, setCharacter] = useState({})

    //handling the ability to edit the character on the same page
    const [editing, setEditing] = useState(false)

    //handling the edit input to be sumbitted for update later
    const [debutInput, setDebutInput] = useState("")
    const [debutYearInput, setDebutYearInput] = useState("")

    const navigate = useNavigate()


    //runs when name or editing is toggled
    //runs when you initially arrive at the page to fetch char info based on the name param passed down
    useEffect(() => {
        fetch(`${API_URL}/api/getOneCharacter/${name}`, {
            
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(async res => {
        const data = await res.json()
        //setting character to the character displayed on the page
        setCharacter(data.payload)
        //setting the character info to the input info for editing later
        setDebutInput(data.payload.debut)
        setDebutYearInput(data.payload.debutYear)
        })
    }, [name, editing])


    //toggles the ability to edit
    //changes the tags to input fields to be able to update char on same page
    function toggleEditing(){
        editing ? setEditing(false) : setEditing(true)
    }

    //handling the submit to make the put request
    function handleOnSubmit(e){
        e.preventDefault()
        console.log('submitted!');

        //new object for the body with char info
        let sendBody = {
            debut: debutInput,
            debutYear: debutYearInput
        }

        //fetch put request to update the players
        fetch(`${API_URL}/api/updateOneCharacter/${character._id}`, {
            method: "put",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            //stringify the body object
            body: JSON.stringify(sendBody)
        }).then(async res => {
            //setting edit to false to run the useeffect
            setEditing(false)
            
        })
    }
    
    function handleDelete(e) {
        e.preventDefault()

        fetch(`${API_URL}/api/deleteCharacter/${character._id}`, {
            method: "delete",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
        }).then( () => {
            navigate('/mcu/')
        })
    }

    
    

    return(
        <>
            <h1>The character {character.name} debuted in the film {character.debut} which was released in {character.debutYear}</h1>
            <ul>
            <form onSubmit={handleOnSubmit}>
                <li>
                    debuted in &nbsp;
                    {editing ? 
                    //getting the input value on change to use to update the character later
                    <input type="test" name="debut" value={debutInput} onChange={(e) => setDebutInput(e.target.value)} /> :
                    <span>{character.debut}</span> 
                    }
                    
                </li>
                <li>
                    released in year &nbsp;
                    {editing ? 
                    <input type="test" name="debutYear" value={debutYearInput} onChange={(e) => setDebutYearInput(e.target.value)}/> :
                    <span>{character.debutYear}</span> 
                    }
                </li>
                {editing ? <button type="submit" >update character</button> : <br/>}
            </form>
                
            </ul>
            <button onClick={toggleEditing}>
            {editing ? 
                "Stop editing" :
                "Edit character details"
            }
            </button>
            <button onClick={handleDelete}>Delete this character</button>
        </>
    )
}

export default OneCharacter