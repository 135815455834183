import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

function CreateCharacter() {
    const [name, setName] = useState("")
    const [debut, setDebut] = useState("")
    const [debutYear, setDebutYear] = useState(0)

    const navigate = useNavigate()

    async function postCharacter(){
        //new object for the character/body
        let newChar = {
                name: name,
                debut: debut,
                debutYear: debutYear
        }
        fetch('https://mcu-backend.onrender.com/api/createCharacter', {
            //request options
            method: 'post',
            headers:{
                "Content-Type": "application/json",
                "x-access-token": "token-value",
            },
            body: JSON.stringify(newChar)
        }).then(async res => {
            let serverResponse = await res.json()
            // console.log(serverResponse);

            navigate(`/mcu/${serverResponse.payload.name}`)
        })
        //resetting the data after submission
        setName('')
        setDebut('')
        setDebutYear(0)
    }

    function handleOnSubmit(event){
        event.preventDefault();
        postCharacter()

    }

    return (
        <form onSubmit={handleOnSubmit}>
            <label for="name">Name</label>
            <input value={name} onChange={(e) => setName(e.target.value)}/> <br />
            <label for="name">Debut</label>

            <input value={debut} onChange={(e) => setDebut(e.target.value)}/>
            <label for="name">Debut Year</label>

            <input type="number" value={debutYear} onChange={(e) => setDebutYear(e.target.value)}/>
            <button type="submit">submit</button>
        </form>
    );
}

export default CreateCharacter;